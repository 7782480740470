import { template as template_3fccfc59c8e648528aa2f1589d8a5504 } from "@ember/template-compiler";
const FKLabel = template_3fccfc59c8e648528aa2f1589d8a5504(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
