import { template as template_773b9f6543444fafae7b121f2c887dc9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_773b9f6543444fafae7b121f2c887dc9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
