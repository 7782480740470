import { template as template_d31bedb93f8043f8b3f2dc38f2750b46 } from "@ember/template-compiler";
const WelcomeHeader = template_d31bedb93f8043f8b3f2dc38f2750b46(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
